import Link from 'next/link';
import { useState } from 'react';
import { FaUser, FaSignInAlt, FaBars } from 'react-icons/fa';
import { Button } from "../@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "../@/components/ui/sheet";
import LoginPopup from './LoginPopup';
import RegistrationPopup from './RegistrationPopup';
import SubmitTablePopup from './SubmitTablePopup';

const Navbar = () => {
  const [showRegistration, setShowRegistration] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSubmitTable, setShowSubmitTable] = useState(false);

  const handleSwitchToRegister = () => {
    setShowLogin(false);
    setShowRegistration(true);
  };

  const handleSwitchToLogin = () => {
    setShowRegistration(false);
    setShowLogin(true);
  };

  return (
    <nav className="bg-background border-b">
      <div className="container mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          <Link href="/">
            <span className="text-2xl font-bold">Mezzpro</span>
          </Link>
          <div className="hidden md:flex space-x-4 items-center">
            <Button
              variant="outline"
              onClick={() => setShowSubmitTable(true)}
            >
              Submit Table
            </Button>
            <Link href="/about">
              <Button variant="ghost">About</Button>
            </Link>
            <Button
              variant="outline"
              onClick={() => setShowLogin(true)}
            >
              <FaSignInAlt className="mr-2" />
              Login
            </Button>
            <Button
              onClick={() => setShowRegistration(true)}
            >
              <FaUser className="mr-2" />
              Register
            </Button>
          </div>
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon" className="md:hidden">
                <FaBars size={24} />
              </Button>
            </SheetTrigger>
            <SheetContent side="right" className="w-[300px] sm:w-[400px]">
              <nav className="flex flex-col space-y-4">
                <Link href="/about">
                  <Button variant="ghost" className="w-full justify-start">About</Button>
                </Link>
                <Button
                  variant="outline"
                  onClick={() => setShowLogin(true)}
                  className="w-full justify-start"
                >
                  Login
                </Button>
                <Button
                  onClick={() => setShowRegistration(true)}
                  className="w-full justify-start"
                >
                  Register
                </Button>
              </nav>
            </SheetContent>
          </Sheet>
        </div>
      </div>
      <LoginPopup 
        open={showLogin} 
        onOpenChange={setShowLogin} 
        onSwitchToRegister={handleSwitchToRegister}
      />
      <RegistrationPopup 
        open={showRegistration} 
        onOpenChange={setShowRegistration}
        onSwitchToLogin={handleSwitchToLogin}
      />
      <SubmitTablePopup 
        open={showSubmitTable} 
        onOpenChange={setShowSubmitTable}
      />
    </nav>
  );
};

export default Navbar;