import Link from 'next/link';
import { motion } from 'framer-motion';
import { FaRocket } from 'react-icons/fa';
import { Button } from "@/components/ui/button";

const Hero = () => {
  return (
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-primary py-16 rounded-lg shadow-xl mb-12"
    >
      <div className="container mx-auto px-4 text-center">
        <h1 className="text-5xl font-bold mb-6 text-primary-foreground">Welcome to Mezzpro 1.0.10 preview demo test new</h1>
        <p className="text-xl mb-10 text-primary-foreground/80 max-w-2xl mx-auto">
          Revolutionize your business with secure invoice tokenization. Simplify processes and ensure compliance effortlessly.
        </p>
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Link href="/get-started" passHref>
            <Button 
              size="lg" 
              className="bg-background text-primary hover:bg-background/90 px-8 py-6 text-lg font-semibold"
            >
              <FaRocket className="mr-2" />
              Get Started
            </Button>
          </Link>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Hero;