import { useState } from 'react';
import { motion } from 'framer-motion';
import { FaPaperPlane } from 'react-icons/fa';
import { Input } from "../@/components/ui/input";
import { Button } from "./ui/button";
import CustomToast from './CustomToast';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const Submit = () => {
  const [submitTerm, setSubmitTerm] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const response = await fetch(`${publicRuntimeConfig.backendUrl}/submit/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ submit_term: submitTerm }),
      });
      const data = await response.json();
      
      if (response.ok) {
        setToastMessage(`Term "${submitTerm}" submitted successfully!`);
        setIsSuccess(true);
        setSubmitTerm('');
      } else {
        setToastMessage(data.detail || "An error occurred");
        setIsSuccess(false);
      }
    } catch (error) {
      setToastMessage("An error occurred while submitting the term.");
      setIsSuccess(false);
    }
  };

  const closeToast = () => {
    setToastMessage('');
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="my-16 max-w-2xl mx-auto"
      >
        <h2 className="text-3xl font-bold text-center mb-8 text-slate-900">Submit a Term</h2>
        <form onSubmit={handleSubmit} className="flex items-center space-x-2">
          <Input
            type="text"
            value={submitTerm}
            onChange={(e) => setSubmitTerm(e.target.value)}
            placeholder="Enter the term to submit to database..."
            className="flex-grow"
          />
          <Button type="submit" size="icon" className="bg-blue-600 hover:bg-blue-700">
            <FaPaperPlane />
          </Button>
        </form>
      </motion.div>
      <CustomToast message={toastMessage} isSuccess={isSuccess} onClose={closeToast} />
    </>
  );
};

export default Submit;